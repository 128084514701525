import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './LoginPage';
import AdminPage from './AdminPage';
import MainApp from './MainApp';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true); // Midlertidig tilstand til admin-adgang
  const [accountId, setAccountId] = useState(null);

  const handleLogin = (id) => {
    setIsLoggedIn(true);
    setAccountId(id);
  };

  return (
    <Router>
      <Routes>
        {/* Rute til AdminPage */}
        {isAdmin && (
          <Route path="/admin" element={<AdminPage />} />
        )}

        {/* Rute til hovedappen (MainApp) */}
        <Route
          path="/"
          element={
            isLoggedIn ? <MainApp accountId={accountId} /> : <LoginPage onLogin={handleLogin} />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
