import React, { useState, useEffect } from 'react';
import { Handle } from 'reactflow';

function CustomerMessageNode({ data }) {
  const [message, setMessage] = useState(data.message || '');
  // Synkroniserer lokal state med nodens data
  useEffect(() => {
    data.message = message;
  }, [message, data]);
  return (
    <div style={{ padding: '10px', border: '2px solid #00cc66', borderRadius: '5px', backgroundColor: '#e6fff2' }}>
      <strong>{data.label}</strong>
      <p>Lytter efter kundens besked...</p>
      <p>Besked til kunden:</p>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Skriv besked til kunden her"
        style={{ width: '100%', height: '60px', padding: '5px' }}
      />

      {/* Handlers */}
      <Handle type="target" position="top" id="target" style={{ background: '#555' }} />
      <Handle type="source" position="bottom" id="source" style={{ background: '#555' }} />
    </div>
  );
}

export default CustomerMessageNode;
