import React, { useState } from 'react';
import { Handle } from 'reactflow';

function IfElseNode({ data }) {
  const [conditions, setConditions] = useState([
    { id: 1, value1: '', operator: 'is equal to', value2: '', type: 'String' },
  ]);

  const variables = ['Kundens spørgsmål', 'Seneste AI-besked']; // Eksempel på variabler

  const addCondition = () => {
    const newCondition = { id: conditions.length + 1, value1: '', operator: 'is equal to', value2: '', type: 'String' };
    setConditions([...conditions, newCondition]);
  };

  const updateCondition = (id, field, value) => {
    setConditions(conditions.map(cond => (cond.id === id ? { ...cond, [field]: value } : cond)));
  };

  const deleteCondition = (id) => {
    setConditions(conditions.filter(cond => cond.id !== id));
  };

  return (
    <div style={{ padding: '10px', border: '2px solid #ff6666', borderRadius: '5px', backgroundColor: '#ffe6e6' }}>
      <strong>{data.label}</strong>
      <p>If/Else Node:</p>

      <div>
        {conditions.map((cond) => (
          <div key={cond.id} style={{ display: 'flex', marginBottom: '10px' }}>
            {/* Value 1 (variabel eller manuelt input) */}
            <select
              value={cond.value1}
              onChange={(e) => updateCondition(cond.id, 'value1', e.target.value)}
              style={{ marginRight: '10px', padding: '5px', width: '150px' }}
            >
              <option value="">Vælg variabel</option>
              {variables.map((variable, index) => (
                <option key={index} value={variable}>{variable}</option>
              ))}
            </select>

            {/* Operator */}
            <select
              value={cond.operator}
              onChange={(e) => updateCondition(cond.id, 'operator', e.target.value)}
              style={{ marginRight: '10px', padding: '5px', width: '150px' }}
            >
              <option>is equal to</option>
              <option>is not equal to</option>
              <option>contains</option>
              <option>does not contain</option>
              <option>is greater than</option>
              <option>is less than</option>
            </select>

            {/* Value 2 */}
            <input
              type="text"
              value={cond.value2}
              onChange={(e) => updateCondition(cond.id, 'value2', e.target.value)}
              placeholder="Værdi"
              style={{ marginRight: '10px', padding: '5px', width: '100px' }}
            />

            {/* Datatype */}
            <select
              value={cond.type}
              onChange={(e) => updateCondition(cond.id, 'type', e.target.value)}
              style={{ marginRight: '10px', padding: '5px', width: '120px' }}
            >
              <option>String</option>
              <option>Number</option>
              <option>Date & Time</option>
              <option>Boolean</option>
            </select>

            {/* Slet betingelse */}
            <button
              onClick={() => deleteCondition(cond.id)}
              style={{ padding: '5px', backgroundColor: 'red', color: 'white', borderRadius: '5px' }}
            >
              Slet
            </button>
          </div>
        ))}
      </div>

      <button onClick={addCondition} style={{ marginTop: '10px', padding: '5px 10px' }}>Tilføj betingelse</button>

      {/* Handles med labels for True/False */}
      <div style={{ position: 'relative', marginTop: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
          {/* False-handle */}
          <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <Handle type="source" position="left" id="false" style={{ background: '#dc3545', marginRight: '10px' }} />
            <span style={{ marginLeft: '5px' }}>False</span>
          </div>

          {/* True-handle */}
          <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <span style={{ marginRight: '5px' }}>True</span>
            <Handle type="source" position="right" id="true" style={{ background: '#28a745', marginLeft: '10px' }} />
          </div>
        </div>
      </div>

      {/* Source-handle */}
      <Handle type="target" position="top" id="target" style={{ background: '#555', marginTop: '10px' }} />
    </div>
  );
}

export default IfElseNode;
