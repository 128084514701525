import React, { useState } from 'react';

function AdminPage() {
  const [accountId, setAccountId] = useState('');
  const [accountName, setAccountName] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [message, setMessage] = useState('');

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    setMessage('');
  
    try {
      const response = await fetch('http://164.90.224.227:3001/api/createAccount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ account_id: accountId, account_name: accountName, api_key: apiKey  }),
      });
  
      const data = await response.json();
      if (response.ok && data.flow) {
        setMessage(`Account created successfully with ID: ${data.flow.account_id}`);
      } else {
        setMessage(data.message || 'Failed to create account');
      }
    } catch (error) {
      setMessage('An error occurred while creating the account');
      console.error('Error:', error);
    }
  };

  return (
    <div className="admin-container">
      <h2>Create a New Customer Account</h2>
      <form onSubmit={handleCreateAccount}>
        <div className="input-group">
          <label>Account ID:</label>
          <input
            type="text"
            value={accountId}
            onChange={(e) => setAccountId(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Account Name:</label>
          <input
            type="text"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>API nøgle KS</label>
          <input
            type="text"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            required
          />
        </div>
        <button type="submit">Create Account</button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
}

export default AdminPage;
