import React, { useState } from 'react';
import { Handle } from 'reactflow';

function CollectDataNode({ data }) {
  const [fields, setFields] = useState([
    { id: 1, label: 'Hvad er dit navn?', type: 'text', required: true }
  ]);

  const addField = () => {
    const newField = { id: fields.length + 1, label: '', type: 'text', required: true };
    setFields([...fields, newField]);
  };

  const updateField = (id, field, value) => {
    setFields(fields.map(f => (f.id === id ? { ...f, [field]: value } : f)));
  };

  const deleteField = (id) => {
    setFields(fields.filter(f => f.id !== id));
  };

  return (
    <div style={{ padding: '10px', border: '2px solid #007bff', borderRadius: '5px', backgroundColor: '#e6f0ff' }}>
      <strong>{data.label}</strong>
      <p>Dataindsamlingsnode:</p>

      <div>
        {fields.map((field) => (
          <div key={field.id} style={{ display: 'flex', marginBottom: '10px' }}>
            <input
              type="text"
              value={field.label}
              onChange={(e) => updateField(field.id, 'label', e.target.value)}
              placeholder="Skriv spørgsmål"
              style={{ marginRight: '10px', padding: '5px', width: '150px' }}
            />

            <select
              value={field.type}
              onChange={(e) => updateField(field.id, 'type', e.target.value)}
              style={{ marginRight: '10px', padding: '5px', width: '100px' }}
            >
              <option value="text">Tekst</option>
              <option value="email">E-mail</option>
              <option value="tel">Telefonnummer</option>
            </select>

            {/* Checkbox for om feltet er påkrævet */}
            <label style={{ marginRight: '10px' }}>
              Påkrævet:
              <input
                type="checkbox"
                checked={field.required}
                onChange={(e) => updateField(field.id, 'required', e.target.checked)}
                style={{ marginLeft: '5px' }}
              />
            </label>

            {/* Slet felt */}
            <button
              onClick={() => deleteField(field.id)}
              style={{ padding: '5px', backgroundColor: 'red', color: 'white', borderRadius: '5px' }}
            >
              Slet
            </button>
          </div>
        ))}
      </div>

      <button onClick={addField} style={{ marginTop: '10px', padding: '5px 10px' }}>Tilføj felt</button>

      {/* Handles */}
      <Handle type="target" position="top" id="target" style={{ background: '#555' }} />
      <Handle type="source" position="bottom" id="source" style={{ background: '#555' }} />
    </div>
  );
}

export default CollectDataNode;
