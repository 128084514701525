// MainApp.js
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import StartNode from './nodes/StartNode';
import WelcomeNode from './nodes/WelcomeNode';
import AINode from './nodes/AINode';
import ForwardNode from './nodes/ForwardNode';
import ButtonNode from './nodes/ButtonNode';
import DecisionNode from './nodes/DecisionNode';
import DelayNode from './nodes/DelayNode';
import IfElseNode from './nodes/IfElseNode';
import CollectDataNode from './nodes/CollectDataNode';
import EndNode from './nodes/EndNode';
import CustomerMessageNode from './nodes/CustomerMessageNode';
import PredefinedMessageNode from './nodes/PredefinedMessageNode';

const flowKey = 'example-flow';

const nodeOptions = [
  { label: 'Start', type: 'startnode' },
  { label: 'Velkomstbesked', type: 'welcomenode' },
  { label: 'AI', type: 'ainode' },
  { label: 'Videresendelse til medarbejder', type: 'forwardnode' },
  { label: 'Knapper', type: 'buttonnode' },
  { label: 'Beslutning', type: 'decisionnode' },
  { label: 'Ventetid', type: 'delaynode' },
  { label: 'Betingelse', type: 'ifelsenode' },
  { label: 'Indsaml data', type: 'collectdatanode' },
  { label: 'Slut', type: 'endnode' },
  { label: 'Lyt efter kundebesked', type: 'customermessagenode' },
  { label: 'Prædefineret besked', type: 'predefinedmessagenode' },
];

const saveFlowToBackend = async (flowData, accountId, environment) => {
  try {
    const dataToSend = { ...flowData, account_id: accountId, environment };

    console.log('Data sent to backend:', dataToSend);

    const response = await fetch('http://164.90.224.227:3001/api/saveFlow', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataToSend),
    });

    const result = await response.text();
    console.log(result);
  } catch (error) {
    console.error('Fejl ved gemning af flow:', error);
  }
};



const fetchFlowsFromBackend = async (accountId, setNodes, setEdges, setEnvironment) => {
  try {
    const response = await fetch(
      `http://164.90.224.227:3001/api/getFlows/${accountId}`
    );
    const flows = await response.json();
    if (flows && flows.length > 0) {
      const flow = flows[0];

      // Set environment state based on fetched flow data
      setEnvironment(flow.environment); // <- Tilføj denne linje

      // Konverter nodetyper til små bogstaver for konsistens
      const nodesWithLowerCaseTypes = flow.nodes.map((node) => ({
        ...node,
        type: node.type.toLowerCase(),
      }));

      // Re-assign 'onChange' funktion til 'decisionnode' typer
      const nodesWithOnChange = nodesWithLowerCaseTypes.map((node) => {
        if (node.type === 'decisionnode') {
          const nodeId = node.id;
          return {
            ...node,
            data: {
              ...node.data,
              onChange: (dataUpdate) => {
                setNodes((nds) =>
                  nds.map((n) =>
                    n.id === nodeId
                      ? { ...n, data: { ...n.data, ...dataUpdate } }
                      : n
                  )
                );
              },
            },
          };
        }
        return node;
      });

      setNodes(nodesWithOnChange);
      setEdges(flow.edges);
    }
  } catch (error) {
    console.error('Fejl ved hentning af flows:', error);
  }
};



function MainApp({ accountId }) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesState] = useEdgesState([]);
  const [rfInstance, setRfInstance] = useState(null);
  const [environment, setEnvironment] = useState('production');

  const nodeTypes = useMemo(
    () => ({
      startnode: StartNode,
      welcomenode: WelcomeNode,
      ainode: AINode,
      forwardnode: ForwardNode,
      buttonnode: ButtonNode,
      decisionnode: DecisionNode,
      delaynode: DelayNode,
      ifelsenode: IfElseNode,
      collectdatanode: CollectDataNode,
      endnode: EndNode,
      customermessagenode: CustomerMessageNode,
      predefinedmessagenode: PredefinedMessageNode,
    }),
    []
  );

  useEffect(() => {
    if (accountId) {
      fetchFlowsFromBackend(accountId, setNodes, setEdges, setEnvironment);
    }
  }, [accountId, setNodes, setEdges, setEnvironment]);
  

  // Traversal function to sort nodes in connection order
  const traverseFlow = (startNodeId, nodes, edges) => {
    console.log('Starting traversal from node:', startNodeId);
    const visited = new Set();
    const orderedNodes = [];
    const queue = [startNodeId];

    const nodeMap = new Map(nodes.map((node) => [node.id, node]));
    const edgeMap = edges.reduce((acc, edge) => {
      if (!acc[edge.source]) {
        acc[edge.source] = [];
      }
      acc[edge.source].push(edge.target);
      return acc;
    }, {});

    console.log('Edge Map:', edgeMap);

    while (queue.length > 0) {
      const nodeId = queue.shift();
      if (!visited.has(nodeId)) {
        visited.add(nodeId);
        const node = nodeMap.get(nodeId);
        if (node) {
          orderedNodes.push(node);
          const neighbors = edgeMap[nodeId] || [];
          console.log(`Visiting node ${nodeId}, neighbors:`, neighbors);
          queue.push(...neighbors);
        }
      }
    }

    console.log('Ordered Nodes after traversal:', orderedNodes);
    return orderedNodes;
  };

  // Updated function to traverse from a given node
  const traverseFromNode = (startNodeId, nodes, edges) => {
    console.log(`Traversing from node: ${startNodeId}`);
    const visited = new Set();
    const queue = [startNodeId];
    const collectedNodes = [];
    const collectedEdges = [];

    const nodeMap = new Map(nodes.map((node) => [node.id, node]));
    const edgeMap = edges.reduce((acc, edge) => {
      if (!acc[edge.source]) {
        acc[edge.source] = [];
      }
      acc[edge.source].push(edge);
      return acc;
    }, {});

    console.log('Edge Map:', edgeMap);

    while (queue.length > 0) {
      const nodeId = queue.shift();
      if (!visited.has(nodeId)) {
        visited.add(nodeId);
        const node = nodeMap.get(nodeId);
        if (node) {
          collectedNodes.push(node);
          console.log(`Visited node: ${nodeId}`);

          const outgoingEdges = edgeMap[nodeId] || [];
          console.log(`Outgoing edges from node ${nodeId}:`, outgoingEdges);

          for (let edge of outgoingEdges) {
            collectedEdges.push(edge);
            const targetNodeId = edge.target;
            if (!visited.has(targetNodeId)) {
              queue.push(targetNodeId);
            }
          }
        }
      }
    }

    console.log('Collected Nodes:', collectedNodes);
    console.log('Collected Edges:', collectedEdges);

    return { nodes: collectedNodes, edges: collectedEdges };
  };

  const onSave = useCallback(async (environment) => {
    if (nodes.length > 0) {
      const startNode = nodes.find((node) => node.type === 'startnode');
      if (!startNode) {
        alert('Ingen startnode fundet. Sørg for at have en startnode i dit flow.');
        return;
      }
  
      const orderedNodes = traverseFlow(startNode.id, nodes, edges);
  
      console.log('Ordered Nodes:', orderedNodes);
      console.log('Edges:', edges);
  
      const decisionNode = nodes.find((node) => node.type === 'decisionnode');
      let decisions = [];
      if (decisionNode) {
        for (let choice of decisionNode.data.choices) {
          if (choice.connectedNodeId) {
            const { nodes: choiceNodes, edges: choiceEdges } = traverseFromNode(
              choice.connectedNodeId,
              nodes,
              edges
            );
            decisions.push({
              choiceId: choice.id,
              label: choice.label,
              connectedNodeId: choice.connectedNodeId,
              nodes: choiceNodes,
              edges: choiceEdges,
            });
          }
        }
      }
  
      const nodesToSave = orderedNodes.map((node) => {
        const { onChange, ...dataWithoutFunctions } = node.data;
        return {
          ...node,
          data: dataWithoutFunctions,
          position: {
            x: node.position.x,
            y: node.position.y,
          },
        };
      });
  
      const edgesToSave = edges;
  
      console.log('Decisions to save:', decisions);
  
      await saveFlowToBackend(
        {
          name: 'My Custom Flow',
          nodes: nodesToSave,
          edges: edgesToSave,
          decisions: decisions,
        },
        accountId,
        environment // Passér environment videre
      );
      console.log('Flow opdateret succesfuldt');
    }
  }, [nodes, edges, accountId]);
  

  const onConnect = useCallback(
    (params) => {
      console.log('onConnect params:', params);
      setEdges((eds) => {
        const newEdges = addEdge(params, eds);
        console.log('Edges after adding new edge:', newEdges);
        return newEdges;
      });
  
      setNodes((nds) =>
        nds.map((node) => {
          if (
            node.id === params.source &&
            node.type.toLowerCase() === 'decisionnode'
          ) {
            const sourceHandleId = params.sourceHandle;
            const choiceId = parseInt(sourceHandleId.split('-')[1], 10);
  
            const updatedChoices = node.data.choices.map((choice) =>
              choice.id === choiceId
                ? {
                    ...choice,
                    connectedNodeId: params.target,
                  }
                : choice
            );
  
            console.log('Opdaterede valgmuligheder:', updatedChoices);
  
            // Brug 'onChange' til at opdatere nodens data
            if (node.data.onChange) {
              node.data.onChange({ choices: updatedChoices });
            }
  
            return node;
          }
          return node;
        })
      );
    },
    [setEdges, setNodes]
  );

  // Funktion til at initialisere data for forskellige nodetyper
  const initializeNodeData = (type, nodeId) => {
    switch (type.toLowerCase()) {
      case 'welcomenode':
        return { message: '' };
      case 'decisionnode':
        return {
          choices: [
            { id: 1, label: 'Valg 1', connectedNodeId: null },
            { id: 2, label: 'Valg 2', connectedNodeId: null },
          ],
          // 'message' håndteres i DecisionNode.js
        };
      case 'endnode':
        return { endMessage: '', buttonText: '' };
      // Tilføj initialisering for andre nodetyper efter behov
      default:
        return {};
    }
  };

  const nodeInitialization = useCallback(
    (node) => {
      const nodeId = `${+new Date()}`;
      const newNode = {
        id: nodeId,
        data: {
          label: `${node.label} Node`,
          ...initializeNodeData(node.type, nodeId),
        },
        type: node.type.toLowerCase(),
        position: { x: Math.random() * 400, y: Math.random() * 400 },
      };
      return newNode;
    },
    [setNodes]
  );

  return (
    <div style={{ height: '100vh' }}>
      <ReactFlowProvider>
        <div style={{ display: 'flex', height: '100%' }}>
          <div
            style={{
              width: '250px',
              padding: '20px',
              background: '#f8f8f8',
              borderRight: '1px solid #ddd',
              boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
            }}
          >
            <h3
              style={{
                fontSize: '1.2em',
                marginBottom: '20px',
                color: '#333',
              }}
            >
              Tilføj en node
            </h3>
            {nodeOptions.map((node) => (
              <button
                key={node.label}
                onClick={() =>
                  setNodes((nds) => [...nds, nodeInitialization(node)])
                }
                style={{
                  display: 'block',
                  margin: '10px 0',
                  padding: '10px 15px',
                  width: '100%',
                  background: '#007BFF',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '1em',
                  cursor: 'pointer',
                  transition: 'background 0.3s',
                }}
                onMouseOver={(e) => (e.target.style.background = '#0056b3')}
                onMouseOut={(e) => (e.target.style.background = '#007BFF')}
              >
                {node.label}
              </button>
            ))}
          </div>
          <div style={{ flexGrow: 1 }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesState}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              fitView
              onInit={setRfInstance}
            />
          </div>
          {/* Informationsboks med flere sektioner og account ID */}
          <div
            style={{
              width: '250px',
              padding: '20px',
              background: '#f8f8f8',
              borderLeft: '1px solid #ddd',
              boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.1)',
            }}
          >
            <h3 style={{ fontSize: '1.2em', marginBottom: '20px', color: '#333' }}>
              Information
            </h3>
            <div
              style={{
                padding: '10px',
                marginBottom: '10px',
                background: '#fff',
                borderRadius: '5px',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
              }}
            >
              <h4 style={{ fontSize: '1em', color: '#555' }}>Konto-ID</h4>
              <p style={{ fontSize: '0.9em', color: '#777' }}>
                Account ID: {accountId}
              </p>
            </div>
            <div
              style={{
                padding: '10px',
                marginBottom: '10px',
                background: '#fff',
                borderRadius: '5px',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
              }}
            >
              <h4 style={{ fontSize: '1em', color: '#555' }}>Om projektet</h4>
              <p style={{ fontSize: '0.9em', color: '#777' }}>
                Dette er en flow-builder, hvor du kan bygge chatbots.
              </p>
            </div>
            <div
              style={{
                padding: '10px',
                marginBottom: '10px',
                background: '#fff',
                borderRadius: '5px',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
              }}
            >
              <h4 style={{ fontSize: '1em', color: '#555' }}>Tips</h4>
              <p style={{ fontSize: '0.9em', color: '#777' }}>
                Hvis du oplever problemer efter en Beslutnigngsnode, så prøv at fjerne connection til de to routes og tilføj dem igen.
              </p>
            </div>
            <div
              style={{
                padding: '10px',
                marginBottom: '10px',
                background: '#fff',
                borderRadius: '5px',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
              }}
            >
              <h4 style={{ fontSize: '1em', color: '#555' }}>Hjælp</h4>
              <p style={{ fontSize: '0.9em', color: '#777' }}>
                Kontakt Emil og derefter Anders.
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            display: 'flex',
            gap: '10px',
          }}
        >
          <select
            value={environment}
            onChange={(e) => setEnvironment(e.target.value)}
            style={{
              padding: '10px 15px',
              fontSize: '1em',
              borderRadius: '5px',
              border: '1px solid #ddd',
              cursor: 'pointer',
            }}
          >
            <option value="production">Production</option>
            <option value="test">Test</option>
          </select>
          <button
            onClick={() => onSave(environment)} // Tilføj environment som parameter
            style={{
              padding: '10px 20px',
              backgroundColor: '#28a745',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              fontSize: '1em',
              cursor: 'pointer',
              transition: 'background 0.3s',
            }}
          >
            Gem Flow
          </button>
        </div>

      </ReactFlowProvider>
    </div>
  );     
}

export default MainApp;
