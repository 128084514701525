import React, { useState } from 'react';
import { Handle } from 'reactflow';

function DelayNode({ data }) {
  const [delay, setDelay] = useState(0); // Default ventetid er 0 sekunder

  return (
    <div style={{ padding: '10px', border: '2px solid #ffcc00', borderRadius: '5px', backgroundColor: '#fffbe6' }}>
      <strong>{data.label}</strong>
      <p>Ventetidsnode:</p>

      <label htmlFor="delay-input">Ventetid (sekunder):</label>
      <input
        id="delay-input"
        type="number"
        value={delay}
        onChange={(e) => setDelay(e.target.value)}
        placeholder="Angiv ventetid"
        style={{ marginLeft: '10px', padding: '5px', width: '80px' }}
      />

      {/* Source-handle i toppen for at forbinde node til andre */}
      <Handle type="target" position="top" id="target" style={{ background: '#555' }} />

      {/* Target-handle i bunden for at modtage forbindelser */}
      <Handle type="source" position="bottom" id="source" style={{ background: '#555' }} />
    </div>
  );
}

export default DelayNode;
