import React, { useState } from 'react';
import './LoginPage.css'; // Stilark til at give siden et pænt udseende

function LoginPage({ onLogin }) {
  const [id, setId] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validIDPattern = /^[a-zA-Z0-9]+$/; // Tillad kun bogstaver og tal
    if (!validIDPattern.test(id)) {
      setError('ID må kun indeholde bogstaver og tal.');
      return;
    }

    setError('');
    setLoading(true);

    try {
      // Send login request til backend
      const response = await fetch('http://164.90.224.227:3001/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ account_id: id }),
      });

      if (response.ok) {
        // Login er vellykket, kalder onLogin for at navigere til næste del af appen
        onLogin(id);
      } else {
        // Hvis login fejler, viser vi fejlbeskeden fra backend
        const errorData = await response.json();
        setError(errorData.message || 'Ugyldigt ID.');
      }
    } catch (err) {
      setError('Noget gik galt. Prøv igen senere.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-title">Log ind</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="id-input">ID:</label>
            <input
              type="text"
              id="id-input"
              value={id}
              onChange={(e) => setId(e.target.value)}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? 'Logger ind...' : 'Log ind'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
