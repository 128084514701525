import React, { useState, useEffect } from 'react';
import { Handle } from 'reactflow';

function PredefinedMessageNode({ data }) {
  const [message, setMessage] = useState(data.message || '');

  useEffect(() => {
    data.message = message; // Opdater node's data med beskeden
  }, [message, data]);

  return (
    <div style={{ padding: '10px', border: '2px solid #3399ff', borderRadius: '5px', backgroundColor: '#e6f3ff', width: '220px', minHeight: '180px', position: 'relative' }}>
      <strong>{data.label}</strong>
      <p>Indtast prædefineret besked:</p>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Skriv din besked her..."
        style={{ width: '100%', padding: '5px', borderRadius: '5px', resize: 'both', minHeight: '80px' }}
      />
      <p><strong>Besked:</strong> {message}</p>

      {/* Handles */}
      <Handle type="target" position="top" id="target" style={{ background: '#555' }} />
      <Handle type="source" position="bottom" id="source" style={{ background: '#555' }} />
    </div>
  );
}

export default PredefinedMessageNode;
