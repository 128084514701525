// EndNode.js
import React, { useState, useEffect } from 'react';
import { Handle } from 'reactflow';

function EndNode({ data }) {
  const [endMessage, setEndMessage] = useState(data.endMessage || '');
  const [buttonText, setButtonText] = useState(data.buttonText || '');

  // Synkroniserer lokal state med nodens data
  useEffect(() => {
    data.endMessage = endMessage;
  }, [endMessage, data]);

  useEffect(() => {
    data.buttonText = buttonText;
  }, [buttonText, data]);

  return (
    <div
      style={{
        padding: '10px',
        border: '2px solid #ff0000',
        borderRadius: '5px',
        backgroundColor: '#ffe6e6',
        width: '200px',
      }}
    >
      <strong>{data.label || 'Slut Node'}</strong>
      <p>Samtalen er slut.</p>

      <input
        type="text"
        placeholder="Skriv afslutningsbesked..."
        value={endMessage}
        onChange={(e) => setEndMessage(e.target.value)}
        style={{
          width: '100%',
          marginTop: '10px',
          padding: '5px',
          borderRadius: '3px',
          border: '1px solid #ddd',
        }}
      />

      <input
        type="text"
        placeholder="Skriv knaptekst..."
        value={buttonText}
        onChange={(e) => setButtonText(e.target.value)}
        style={{
          width: '100%',
          marginTop: '5px',
          padding: '5px',
          borderRadius: '3px',
          border: '1px solid #ddd',
        }}
      />

      <Handle
        type="target"
        position="top"
        id="target"
        style={{ background: '#555' }}
      />
    </div>
  );
}

export default EndNode;
