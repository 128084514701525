import React, { useState, useEffect } from 'react';
import { Handle } from 'reactflow';

function ForwardNode({ data }) {
  const [message, setMessage] = useState(data.message || '');
    // Synkroniserer lokal state med nodens data
    useEffect(() => {
      data.message = message;
    }, [message, data]);
  return (
    <div style={{ padding: '10px', border: '2px solid red', borderRadius: '5px', backgroundColor: '#ffe6e6' }}>
      <strong>{data.label}</strong>
      <p>Samtalen bliver videresendt til en medarbejder.</p>
      <p>Videresendelsesbesked:</p>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Skriv videresendelsesbeskeden her..."
        style={{ width: '100%', height: '60px', padding: '5px' }}
      />
      {/* Tilføj handles for at tillade forbindelse til og fra noder */}
      <Handle type="target" position="top" id="target" style={{ background: '#555' }} />
      <Handle type="source" position="bottom" id="source" style={{ background: '#555' }} />
    </div>
  );
}

export default ForwardNode;
