// WelcomeNode.js
import React, { useState, useEffect } from 'react';
import { Handle } from 'reactflow';

function WelcomeNode({ data }) {
  const [message, setMessage] = useState(data.message || '');

  // Synkroniserer lokal state med nodens data
  useEffect(() => {
    data.message = message;
  }, [message, data]);

  return (
    <div
      style={{
        padding: '10px',
        border: '2px solid blue',
        borderRadius: '5px',
        backgroundColor: '#e0f0ff',
        width: '200px',
      }}
    >
      <strong>{data.label || 'Velkomst Node'}</strong>
      <p>Velkomstbesked:</p>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Skriv velkomstbeskeden her..."
        style={{ width: '100%', height: '60px', padding: '5px' }}
      />
      {/* Tilføj handles for at tillade forbindelse til og fra noder */}
      <Handle
        type="target"
        position="top"
        id="target"
        style={{ background: '#555' }}
      />
      <Handle
        type="source"
        position="bottom"
        id="source"
        style={{ background: '#555' }}
      />
    </div>
  );
}

export default WelcomeNode;
