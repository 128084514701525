import React, { useState } from 'react';
import { Handle } from 'reactflow';

function AINode({ data }) {
  const [systemMessage, setSystemMessage] = useState('');

  return (
    <div style={{ padding: '10px', border: '2px solid orange', borderRadius: '5px', backgroundColor: '#fff7e6' }}>
      <strong>{data.label}</strong>
      <p>Systembesked (AI):</p>
      <textarea
        value={systemMessage}
        onChange={(e) => setSystemMessage(e.target.value)}
        placeholder="Skriv systembeskeden her..."
        style={{ width: '100%', height: '60px' }}
      />
      {/* Tilføj handles for at tillade forbindelse til og fra noder */}
      <Handle type="target" position="top" id="target" style={{ background: '#555' }} />
      <Handle type="source" position="bottom" id="source" style={{ background: '#555' }} />
    </div>
  );
}

export default AINode;
