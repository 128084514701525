import React, { useState } from 'react';
import { Handle } from 'reactflow';

function ButtonNode({ data }) {
  const [buttons, setButtons] = useState([{ id: 1, label: 'Tilføj knap' }]);
  
  // Tilføjer en ny knap med tomt label
  const addButton = () => {
    const newButton = { id: buttons.length + 1, label: '' };
    setButtons([...buttons, newButton]);
  };

  // Opdaterer knappens tekst
  const updateButtonLabel = (id, newLabel) => {
    setButtons(buttons.map((btn) => (btn.id === id ? { ...btn, label: newLabel } : btn)));
  };

  return (
    <div style={{ padding: '10px', border: '2px solid purple', borderRadius: '5px', backgroundColor: '#f0e6ff' }}>
      <strong>{data.label}</strong>
      <p>Knapper:</p>
      <div>
        {buttons.map((btn) => (
          <div key={btn.id} style={{ marginBottom: '10px' }}>
            <input
              type="text"
              value={btn.label}
              onChange={(e) => updateButtonLabel(btn.id, e.target.value)}
              placeholder={`Knap ${btn.id}`}
              style={{ marginRight: '10px', padding: '5px' }}
            />
            <button style={{ padding: '5px 10px' }}>
              {btn.label || `Knap ${btn.id}`} {/* Hvis knappens label er tom, vises en standard tekst */}
            </button>
          </div>
        ))}
      </div>
      <button onClick={addButton} style={{ marginTop: '10px', padding: '5px 10px' }}>Tilføj knap</button>
      {/* Tilføj handles for at tillade forbindelse til og fra noder */}
      <Handle type="target" position="top" id="target" style={{ background: '#555' }} />
      <Handle type="source" position="bottom" id="source" style={{ background: '#555' }} />
    </div>
  );
}

export default ButtonNode;
