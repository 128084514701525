import React from 'react';
import { Handle } from 'reactflow';

function StartNode({ data }) {
  return (
    <div style={{
      padding: '15px',
      border: '2px solid #888',
      borderRadius: '10px',
      backgroundColor: '#f9f9f9',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
      textAlign: 'center',
      minWidth: '150px'
    }}>
      <strong style={{ fontSize: '1.2em', color: '#333' }}>{data.label}</strong>
      <p style={{ marginTop: '10px', color: '#555' }}>Dette er startpunktet for flowet.</p>

      {/* Tilføj en target handle i bunden for at modtage forbindelser */}
      <Handle 
        type="source" 
        position="bottom" 
        id="source" 
        style={{
          background: '#555', 
          border: '2px solid #888', 
          width: '12px', 
          height: '12px', 
          borderRadius: '50%' 
        }} 
      />
    </div>
  );
}

export default StartNode;
