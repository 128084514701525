// DecisionNode.js
import React from 'react';
import { Handle } from 'reactflow';

function DecisionNode({ data }) {
  const updateChoiceLabel = (id, newLabel) => {
    const updatedChoices = data.choices.map((choice) =>
      choice.id === id ? { ...choice, label: newLabel } : choice
    );
    if (data.onChange) {
      data.onChange({ choices: updatedChoices });
    }
  };

  const updateMessage = (newMessage) => {
    if (data.onChange) {
      data.onChange({ message: newMessage });
    }
  };

  return (
    <div
      style={{
        padding: '10px',
        border: '2px solid #ffa500',
        borderRadius: '5px',
        backgroundColor: '#fffbe6',
        width: '200px',
      }}
    >
      <strong>{data.label || 'Beslutning Node'}</strong>

      {/* Inputfelt til besked */}
      <textarea
        placeholder="Skriv besked her..."
        value={data.message || ''}
        onChange={(e) => updateMessage(e.target.value)}
        onMouseDown={(e) => e.stopPropagation()}
        style={{
          width: '100%',
          marginTop: '5px',
          padding: '5px',
          borderRadius: '3px',
          border: '1px solid #ddd',
          resize: 'none',
        }}
      />

      {/* Target-handle */}
      <Handle
        type="target"
        position="top"
        id="target"
        style={{ background: '#555' }}
      />

      <div>
        {data.choices.map((choice) => (
          <div
            key={choice.id}
            style={{ position: 'relative', marginBottom: '10px' }}
          >
            <input
              type="text"
              value={choice.label}
              onChange={(e) => updateChoiceLabel(choice.id, e.target.value)}
              onMouseDown={(e) => e.stopPropagation()} // Forhindrer event bubbling
              placeholder={`Rute ${choice.id}`}
              style={{
                width: '100%',
                padding: '5px',
                borderRadius: '3px',
                border: '1px solid #ddd',
              }}
            />
            {/* Source-handle for hver valgmulighed */}
            <Handle
              type="source"
              position="right"
              id={`choice-${choice.id}`}
              style={{
                background: '#555',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '-15px',
                position: 'absolute',
                zIndex: 10,
                pointerEvents: 'all',
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default DecisionNode;
